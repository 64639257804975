import React, { useEffect, useState } from "react";
import useAPI from "../../hooks/UseAPI";
import { MdDone } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { FaMinus } from "react-icons/fa6";
import { IoTrashBin } from "react-icons/io5";
import "./event.style.css";
import { Modal } from "../Modal/Modal";
import { DeleteUserPopup } from "./DeleteUserPopup";

type ListElementPopupProps = {
    eventElementName: string;
    eventElementId: number | string;
    dateStartPart: string;
    timeStartPart: string;
    timeEndPart: string;
    elements: any;
    setElements: (ele: any) => void;
    setVisiblePopup: (val: boolean) => void;
    isAdmin: boolean;
}

export type Participant = {
    id: number;
    name: string;
    lastname: string;
    email: string;
    phoneNumber: number;
    school: string;
    groupSize: number;
    confirmed: boolean;
    confirmedGroup: boolean;
    groupBool: boolean;
    confirmationCode: string;
}

export const ListElementPopup = (props: ListElementPopupProps) => {
    const [participants, setParticipants] = useState<Participant[]>([]);
    const [error, setError] = useState('');
    const [visibleDeleteUser, setVisibleDeleteUser] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<Participant | null>(null);
    const token = sessionStorage.getItem("jwtToken");

    const handleDeleteUserButton = (participant: Participant) => {
        setSelectedUser(participant);
        setVisibleDeleteUser(true);
    };

    const listCheckUserRegistrationApi = useAPI({
        url: `userregistration/checkuserregistrationsforelement/${props.eventElementId}`,
        method: 'GET',
        auth: true,
        token: token ? token : "",
    });

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const loseFocusePopup = document.querySelector(
                ".eventElementListContainer"
            );
            if (loseFocusePopup && !loseFocusePopup.contains(event.target)) {
                props.setVisiblePopup(false);
                setError("");
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [props]);

    useEffect(() => {
        if (props.eventElementId) {
            listCheckUserRegistrationApi.call().then((res) => {
                if (res !== undefined && res.response !== undefined && res?.response.ok && Array.isArray(res.data)) {
                    setParticipants(res.data);
                } else {
                    setError("Brak chętnych uczestników");
                }
            })
        }
    }, [props.eventElementId, visibleDeleteUser]);

    const formatTimePart = (timePart: string) => {
        const [time,] = timePart.split("+");
        return time.slice(0, 5);
    }

    return (
        <>
            {visibleDeleteUser && selectedUser && (
                <Modal>
                    <DeleteUserPopup
                        participant={selectedUser}
                        eventElementName={props.eventElementName}
                        dateStartPart={props.dateStartPart}
                        timeStartPart={props.timeStartPart}
                        timeEndPart={props.timeEndPart}
                        setVisiblePopup={() => setVisibleDeleteUser(false)}
                        elements={props.elements}
                        setElements={props.setElements}
                        eventElementId={props.eventElementId}
                    />
                </Modal>
            )}
            <div className="eventElementListContainer">
                <div className="headerName">
                    <h2>{props.eventElementName}</h2>
                </div>
                <div className="dataHeadList">
                    {props.dateStartPart}
                </div>
                <div className="timeHeadList">
                    {formatTimePart(props.timeStartPart)} - {formatTimePart(props.timeEndPart)}
                </div>
                {error ? (<div className="errorList">{error}</div>) : (
                    <div className="contentList">
                        <div className="textContainerList">
                            <div className="textItemsList"><p>Email</p></div>
                            <div className="textItemsList"><p>Imię i nazwisko</p></div>
                            <div className="textItemsList"><p>Szkoła</p></div>
                            <div className="textItemsList"><p>Nr telefonu</p></div>
                            <div className="textItemsList"><p>Wielkość grupy</p></div>
                            <div className="textItemsList"><p>Potwierdzone</p></div>
                            <div className="textItemsList"><p>Grupa potwierdzona</p></div>
                            {props.isAdmin && (
                                <>
                                    <div className="textItemsList"><p>Usuń</p></div>
                                </>
                            )}
                        </div>
                        {participants.map((participant, index) => (
                            <div key={index} className="infoL textContainerList">
                                <div className="textItemsList email"><p>{participant.email}</p></div>
                                <div className="textItemsList"><p>{participant.name} {participant.lastname}</p></div>
                                <div className="textItemsList"><p>{participant.school || '-'}</p></div>
                                <div className="textItemsList"><p>{participant.phoneNumber}</p></div>
                                <div className="textItemsList"><p>{participant.groupSize}</p></div>
                                <div className="textItemsList">
                                    <p>{participant.confirmed ? <MdDone style={{width: "20px", height: "20px" }}/> : <RxCross1 style={{width: "20px", height: "20px" }}/>}</p>
                                </div>
                                <div className="textItemsList">
                                    <p>{participant.groupBool ? (
                                        participant.confirmedGroup ? (
                                            <MdDone style={{width: "20px", height: "20px" }}/>
                                        ) : (
                                            <RxCross1 style={{width: "20px", height: "20px" }}/>
                                        )
                                    ) : <FaMinus style={{width: "20px", height: "20px" }}/>}</p>
                                </div>
                                {props.isAdmin && (
                                    <>
                                        <div className="textItemsList" onClick={() => handleDeleteUserButton(participant)}>
                                            <IoTrashBin style={{width: "20px", height: "20px" }}/>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};